import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import moment from 'moment'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Hero from 'components/hero'
import Layout from 'components/layout'
import TextContainer from 'components/common/text-container'
import { Heading } from 'components/common/typography'

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;

  .gatsby-image-wrapper {
    flex-shrink: 0;
    margin-right: 20px;

    @media (max-width: 660px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  > :nth-child(2) {
    width: 65%;

    @media (max-width: 660px) {
      width: 100%;
    }
  }
`

const Timestamp = styled.span`
  font-size: 32px;
`

const Title = styled.span`
  font-size: 32px;
`

const BlogPage = ({ data }) => (
  <Layout>
    <Helmet
      title='Blog | Pinya Letters'
      meta={[
        {
          name: 'description',
          content: 'My thoughts, learnings, and happenings',
        },
      ]}
    />
    <Hero file={data.heroImage} />
    <TextContainer>
      <Heading>Blog</Heading>
      <p>Check out my most recent blog posts!</p>
      {data.blogs.edges.map(({ node: { title, slug, date, previewImage } }) => (
        <Fragment key={slug}>
          <Flex>
            <Img fixed={previewImage.fixed} />
            <div>
              <Timestamp>{moment(date).format('DD MMM, Y')}</Timestamp>
              <br />
              <br />
              <Title>
                <Link to={`/blog/${slug}`}>{title}</Link>
              </Title>
            </div>
          </Flex>
        </Fragment>
      ))}
    </TextContainer>
  </Layout>
)

export const query = graphql`
  query BlogPageQuery {
    heroImage: file(name: { eq: "hero6" }) {
      ...HeroFile
    }

    blogs: allContentfulBlogPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          date
          previewImage {
            id

            fixed(width: 200, height: 200, quality: 90) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  }
`

export default BlogPage
